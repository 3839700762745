import { all, fork, put, call, takeEvery, takeLatest, take, cancel } from "redux-saga/effects";
import { actions } from "../reducers/menu";
import { firestore, rsfDB } from "../lib/firebase";
import axios from "axios"; // call은 동기, fork는 비동기 요청
// function* getLayer(action) {
//   const { id } = action;
//   try {
//     const data = yield call(rsfDB.getDocument, `Layer/${id}`);
//     yield put({
//       type: actions.GET_LAYER_SUCCESS,
//       data: data.data(),
//     });
//   } catch (err) {
//     yield put({
//       type: actions.GET_LAYER_FAILURE,
//       error: err.message,
//     });
//   }
// }
function* getAllMenu(action) { const { id } = action; try {
    let query = null;
    query = firestore().collection("Exhibition").doc(id).collection("Menu").where("isDeleted", "==", false).orderBy("order", "asc");
    const snapshot = yield call(rsfDB.getCollection, query);
    const data = [];
    snapshot.forEach(doc => { data.push(Object.assign({ id: doc.id }, doc.data())); });
    const parents = data.filter(item => item.depth === 0);
    const children = data.filter(item => item.depth === 1);
    const filteredData = [];
    parents.forEach(parent => { filteredData.push(parent); children.forEach(child => { if (child.parentId === parent.id) {
        filteredData.push(child);
    } }); });
    yield put({ type: actions.GET_ALL_MENU_SUCCESS, data: filteredData });
}
catch (err) {
    yield put({ type: actions.GET_ALL_MENU_FAILURE, error: err.message });
} }
function* syncAllMenu(action) { const { exhibitionId, roomId } = action; try {
    const res = yield axios.post("https://asia-northeast3-onna-b7783.cloudfunctions.net/getMenuData", { id: exhibitionId, roomId });
    const data = res.data.data;
    const parents = data.filter(item => item.depth === 0);
    const children = data.filter(item => item.depth === 1);
    const filteredData = [];
    parents.forEach(parent => { filteredData.push(parent); children.forEach(child => { if (child.parentId === parent.id) {
        filteredData.push(child);
    } }); });
    yield put({ type: actions.SYNC_ALL_MENU_SUCCESS, data: filteredData, roomId: roomId });
}
catch (err) {
    yield put({ type: actions.SYNC_ALL_MENU_FAILURE, err: err.message });
} }
function* syncAllRoom(action) { const { exhibitionId } = action; try {
    let query = null;
    query = firestore().collection("Exhibition").doc(exhibitionId).collection("Room").where("isDeleted", "==", false);
    const task = yield fork(rsfDB.syncDocument, query, { successActionCreator: snapshot => { const data = []; snapshot.forEach(doc => { data.push(Object.assign(Object.assign({ id: doc.id }, doc.data()), { updatedAt: null, createdAt: null })); }); return { type: actions.SYNC_ALL_ROOM_SUCCESS, data }; }, failureActionCreator: err => ({ type: actions.SYNC_ALL_ROOM_FAILURE, err: err.message }) });
    yield take(actions.SYNC_ALL_ROOM_DONE);
    yield cancel(task);
}
catch (err) {
    yield put({ type: actions.SYNC_ALL_ROOM_FAILURE, error: err.message });
} }
function* postMenu(action) { const { exhibitionId, data, isRoomMenu } = action; try {
    yield call(rsfDB.addDocument, isRoomMenu ? `Exhibition/${exhibitionId}/RoomMenu` : `Exhibition/${exhibitionId}/Menu`, Object.assign(Object.assign({}, data), { isDeleted: false }));
    yield put({ type: actions.POST_MENU_SUCCESS });
}
catch (err) {
    yield put({ type: actions.POST_MENU_FAILURE, error: err.message });
} }
function* updateMenu(action) {
    const { exhibitionId, id, data, isRoomMenu } = action;
    try {
        yield call(rsfDB.updateDocument, isRoomMenu ? `Exhibition/${exhibitionId}/RoomMenu/${id}` : `Exhibition/${exhibitionId}/Menu/${id}`, data // updatedAt: firestore.FieldValue.serverTimestamp(),
        );
        yield put({ type: actions.UPDATE_MENU_SUCCESS });
    }
    catch (err) {
        yield put({ type: actions.UPDATE_MENU_FAILURE, error: err.message });
    }
}
function* deleteMenuData(action) { const { exhibitionId, id, isRoomMenu } = action; try {
    yield call(rsfDB.updateDocument, isRoomMenu ? `Exhibition/${exhibitionId}/RoomMenu/${id}` : `Exhibition/${exhibitionId}/Menu/${id}`, { isDeleted: true });
    yield put({ type: actions.DELETE_MENU_SUCCESS });
}
catch (err) {
    yield put({ type: actions.DELETE_MENU_FAILURE, error: err.message });
} } // function* deleteLayersData(action) {
//   // by :  "exhibition" | "layer"
//   const { exhibitionId } = action;
//   try {
//     if (exhibitionId) {
//       const docs = yield firestore()
//         .collection("Exhibition")
//         .doc(exhibitionId)
//         .collection("Layer")
//         .get();
//       const batch = firestore().batch();
//       docs.forEach(doc => {
//         batch.update(doc.ref, {
//           isDeleted: true,
//         });
//       });
//       yield batch.commit();
//     } else {
//       yield put({
//         type: actions.DELETE_LAYERS_FAILURE,
//         error: "exhibitionId required",
//       });
//     }
//     yield put({
//       type: actions.DELETE_LAYERS_SUCCESS,
//     });
//   } catch (err) {
//     yield put({
//       type: actions.DELETE_LAYERS_FAILURE,
//       error: err.message,
//     });
//   }
// }
// function* watchGetLayer() {
//   yield takeEvery<string>(actions.GET_LAYER_REQUEST, getLayer);
// }
function* watchGetAllMenus() { yield takeEvery(actions.GET_ALL_MENU_REQUEST, getAllMenu); }
function* watchSyncAllMenus() { yield takeEvery(actions.SYNC_ALL_MENU_REQUEST, syncAllMenu); }
function* watchSyncAllRooms() { yield takeEvery(actions.SYNC_ALL_ROOM_REQUEST, syncAllRoom); }
function* watchPostMenu() { yield takeLatest(actions.POST_MENU_REQUEST, postMenu); }
function* watchUpdateMenu() { yield takeLatest(actions.UPDATE_MENU_REQUEST, updateMenu); }
function* watchDeleteMenu() { yield takeLatest(actions.DELETE_MENU_REQUEST, deleteMenuData); } // // function* watchUpdateMenus() {
// //   yield takeLatest<string>(actions.UPDATE_MenuS_REQUEST, updateMenusData);
// // }
// function* watchDeleteMenus() {
//   yield takeLatest<string>(actions.DELETE_MenuS_REQUEST, deleteMenusData);
// }
export default function* MenuSaga() { yield all([fork(watchGetAllMenus), fork(watchSyncAllMenus), fork(watchSyncAllRooms), fork(watchPostMenu), fork(watchUpdateMenu), fork(watchDeleteMenu)]); }
