import { all, fork, call, put, takeEvery, take, cancel } from 'redux-saga/effects';
import { actions } from '../reducers/chat';
import { rsfDB, firestore } from '../lib/firebase'; // import { Notifier } from "../utils/notify/Notifier";
// import { sendNotifier } from "../utils/notify/NotifyPolicy";
// call은 동기, fork는 비동기 요청
function* syncNumberOfChat(action) { const { id, number } = action; const task = yield fork(rsfDB.syncDocument, firestore().collectionGroup('Chat').where('channelId', '==', id).where('isDeleted', '==', false).orderBy('createdAt', 'desc').limit(number), { successActionCreator: data => { let chats = []; data.forEach(doc => { var _doc$data, _doc$data$createdAt; chats = [{ id: doc.id, name: doc.data().name, type: doc.data().type, value: doc.data().value, target: (_doc$data = doc.data()) === null || _doc$data === void 0 ? void 0 : _doc$data.targetId, createdAt: ((_doc$data$createdAt = doc.data().createdAt) === null || _doc$data$createdAt === void 0 ? void 0 : _doc$data$createdAt.seconds) * 1000, channelId: doc.data().channelId, avatarId: doc.data().avatarId }, ...chats]; }); return { type: actions.SYNC_NUMBER_OF_CHAT_SUCCESS, data: chats }; }, failureActionCreator: err => ({ type: actions.SYNC_NUMBER_OF_CHAT_FAILURE, err: err.message }) }); yield take(actions.SYNC_NUMBER_OF_CHAT_DONE); yield cancel(task); }
function* postChat(action) {
    const { id, data } = action; // 댓글 입력을 진행할 경우, 유저를 저장한다.
    yield put({ type: actions.SAVE_USER, name: data.name, password: data.password });
    try {
        const snapshot = yield call(rsfDB.addDocument, `Channel/${id}/Chat`, Object.assign(Object.assign({}, data), { channelId: id, createdAt: firestore.FieldValue.serverTimestamp(), isDeleted: false })); // delete 기능을 위해 생성된 id값 추가
        yield call(rsfDB.updateDocument, `Channel/${id}/Chat/${snapshot.id}`, { id: snapshot.id }); // sendNotifier({
        //   type: Notifier.typeConst.Chat,
        //   exhibitionId: id,
        //   objectId: null,
        //   writer: data.name,
        //   content: data.value,
        // });
        yield put({ type: actions.POST_CHAT_SUCCESS });
    }
    catch (err) {
        yield put({ type: actions.POST_CHAT_FAILURE, error: err.message });
    }
}
function* deleteChat(action) { const { channelId, commentId, password } = action; try {
    const target = yield call(rsfDB.getDocument, firestore().collection('Channel').doc(channelId).collection('Chat').where('id', '==', commentId).where('password', '==', password).limit(1));
    if (target.size > 0)
        yield call(rsfDB.updateDocument, `Channel/${channelId}/Chat/${commentId}`, { isDeleted: true });
    else
        throw new Error('비밀번호가 틀렸습니다. 비밀번호를 다시 입력해주세요');
    yield put({ type: actions.DELETE_CHAT_SUCCESS });
}
catch (err) {
    yield put({ type: actions.DELETE_CHAT_FAILURE, error: err.message });
} }
function* adminDeleteChat(action) { const { channelId, commentId } = action; try {
    yield call(rsfDB.updateDocument, `Channel/${channelId}/Chat/${commentId}`, { isDeleted: true });
    yield put({ type: actions.ADMIN_DELETE_CHAT_SUCCESS });
}
catch (err) {
    yield put({ type: actions.ADMIN_DELETE_CHAT_FAILURE, error: err.message });
} }
function* watchSyncNumberOfChat() { yield takeEvery(actions.SYNC_NUMBER_OF_CHAT_REQUEST, syncNumberOfChat); }
function* watchPostChat() { yield takeEvery(actions.POST_CHAT_REQUEST, postChat); }
function* watchDeleteChat() { yield takeEvery(actions.DELETE_CHAT_REQUEST, deleteChat); }
function* watchAdminDeleteChat() { yield takeEvery(actions.ADMIN_DELETE_CHAT_REQUEST, adminDeleteChat); } // function* watchUpdateGlobalData() {
//   yield takeEvery<string>(actions.UPDATE_GLOBAL_DATA_REQUEST, updateGlobalData);
// }
export default function* chatSaga() { yield all([fork(watchSyncNumberOfChat), fork(watchPostChat), fork(watchDeleteChat), fork(watchAdminDeleteChat)]); }
